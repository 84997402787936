<template>
    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/cashing-records">提现记录</el-breadcrumb-item>
        <el-breadcrumb-item>提现详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider class="div_der"></el-divider>
      <div class="title-header">
        <img :src="imgstate"/>
        <ul>
          <li>{{ mainTitle }}</li>
          <li :style="withdrawaState===2?'color:#FF477E':''">{{ subTitle }}</li>
        </ul>
      </div>
      <ul class="nav" v-if="obj">
        <li><span class="label">提现至</span><span v-if="obj.bankCard"  class="content">{{obj.bankName}}({{obj.bankCard.substring(obj.bankCard.length-4)}})</span></li>
        <li><span class="label">付款方</span><span class="content">{{obj.paymentCompany}}</span></li>
        <li><span class="label">提现金额</span><span class="content">￥{{$toFindexFn(Number(obj.personalTax)+Number(obj.cashOutMoney))}}</span></li>
        <li><span class="label">提交时间</span><span class="content">{{$timestampToString(obj.insetTime)}}</span></li>
        <li><span class="label">税费</span><span class="content">￥{{$toFindexFn(obj.personalTax)}}</span></li>
        <li><span class="label">提现单号</span><span class="content">{{obj.billId}}</span></li>
        <li><span class="label">到账金额</span><span class="content">￥{{$toFindexFn(obj.cashOutMoney)}}</span></li>
      </ul>
      <div class="but">
        <el-button @click="$router.push('/bill/'+obj.id)">查看关联账单</el-button>
      </div>
      <p class="invoice" v-if="invoiceUrlArr.length>0">
        关联发票
      </p>
      <div class="invoice_img">
        <el-image
            class="seeImg"
            v-for="item in invoiceUrlArr"
            :key="item"
            :src="item"
            :preview-src-list="invoiceUrlArr">
        </el-image>
      </div>
      <p class="invoice" v-if="voucherUrlArr.length>0" >
        打款凭证
      </p>
      <div class="invoice_img">
        <el-image
            v-for="item in voucherUrlArr"
            :key="item"
            :src="item"
            :preview-src-list="voucherUrlArr">
        </el-image>
      </div>
    </div>
</template>

<script>
import {cashOutRecordDetailV2} from '../../api/income'

export default {
  name: "Cash-out",
  data() {
    return {
      mainTitle: "",
      subTitle: "",
      imgstate: "",
      withdrawaState: -1,
      invoiceUrlArr:[],
      voucherUrlArr:[],
      obj:{}
    }
  },
  mounted() {
  },
  created() {

    this.cashOutRecordDetailV2Data()
  },
  methods: {
    screenfn(val) {
      this.withdrawaState = val
      switch (val) {
        case 0:
          this.mainTitle = "申请成功，请等待打款！";
          this.subTitle = "申请提现后，预计3个工作日到账，请耐心等待~";
          this.imgstate = require("../../assets/income/WithdrawalInProgress.png");
          break;
        case 1:
          this.mainTitle = "申请成功，请等待打款！";
          this.subTitle = "申请提现后，预计3个工作日到账，请耐心等待~";
          this.imgstate = require("../../assets/income/WithdrawalInProgress.png");
          break;
        case 3:
          this.mainTitle = "您提现的金额已到账！";
          this.subTitle = "如有疑问，可联系海葵分贝客服人员";
          this.imgstate = require("../../assets/income/CashWithdrawal.png");
          break;
        case 2 :
          this.mainTitle = "提现失败！";
          this.subTitle = "您提交的银行卡信息错误，请修正后重新提交！";
          this.imgstate = require("../../assets/income/WithdrawalFailed.png");
          break;
        case 4 :
          this.mainTitle = "提现失败！";
          this.subTitle = "您提交的银行卡信息错误，请修正后重新提交！";
          this.imgstate = require("../../assets/income/WithdrawalFailed.png");
          break;
      }
    },
    async cashOutRecordDetailV2Data(){
       let res = await cashOutRecordDetailV2({id:this.$route.params.id})
      let { appIncomeDetailsEntity:{id}, appUserBank: { bankName,bankCard },cashOutOrder:{invoiceUrl,voucherUrl,paymentCompany,insetTime,cashOutMoney,personalTax,totalStatus}}=res.data
      let billId=res.data.cashOutOrder.orderId
      let invoiceUrlArr=[]
      let voucherUrlArr =[]
      this.screenfn(totalStatus)
      this.obj={id,paymentCompany,insetTime,bankCard,bankName,cashOutMoney,personalTax,billId}
      if(invoiceUrl !==undefined){
     if(invoiceUrl[0]==='h'){
       invoiceUrl.push(invoiceUrl)
     }else {
         JSON.parse(invoiceUrl).forEach(item=>{
           invoiceUrlArr.push(...item.invoiceUrl.split(','))
         })
     }
     this.invoiceUrlArr=invoiceUrlArr
      }
      if(voucherUrl !==undefined){
        if(voucherUrl[0]==='h'){
          voucherUrlArr.push(voucherUrl)
        }else {
          JSON.parse(voucherUrl).forEach(item=>{
            voucherUrlArr.push(item.voucherUrl)
          })
        }
      }
      this.voucherUrlArr=voucherUrlArr
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";
.div_der {
  background-color: #f5f5f5;
  margin: 20px 0 40px 0;
}

.title-header {
  display: flex;

  > img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li:nth-child(1) {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }

    li:nth-child(2) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
    }
  }
}

.nav {
  background: #f9f9f9;
  height: 146px;
  padding: 20px 20px;
  margin-top: 20px;
  list-style: none;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 41px;

  > li {
    > .label {
      display: inline-block;
      width: 48px;
      text-align-last: justify;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      letter-spacing: 0px;
      margin-right: 12px;
    }

    > .content {
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #555555;
      line-height: 14px;
    }
  }
}

.but {
  margin: 40px 0;

  .el-button {
    width: 138px;
    height: 44px;
    background: #ff004d;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
  }
}

.invoice {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #333333;
  letter-spacing: 0px;
  margin-bottom: 24px;
}

.invoice:nth-of-type(2) {
  margin-top: 40px;
}

.invoice_img {
  .el-image {
    width: 170px;
    height: 88px;
    margin-right: 20px;
  }
}
</style>
